import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import dots from 'src/assets/img/vehicles/rightArrow_black.png'
import SearchButton from "src/components/buttons/search-button-component";
import UserTable from "src/components/vehicleDetails/UserTable";
import './dashboard.css';

const UseStyles = makeStyles((theme) => ({
    dividerStyle: {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        marginLeft: -theme.spacing(1),
        marginRight: -theme.spacing(1),
        marginTop: theme.spacing(6),
        height: '2px',
        marginBottom: '10px'
    },
    gridContainerStyle: {
        marginBottom: -theme.spacing(3.7),
        position: 'sticky',
        marginLeft: '11px',
        marginTop: '-22px'
    },
    drawer: {
        width: 250,
        flexShrink: 0,
        textAlign: 'center',
        top: '19.5%',
        marginTop: '80px',
        marginLeft: '40%',
        position: 'relative',
        height: '20px'
    },
    gridContainerStyle1: {
        width: '100%',
    },
    cardStyle: {
        height: '703px',
        borderRadius: '10px',
        marginRight: '-5px',
        border: '2px solid #333333',
        // marginTop: '70px'
    },
    cardStyleLarge: {
        height: '665px',
        borderRadius: '10px',
        marginRight: '-22px'
    },
    imageStyle: {
        marginTop: '50px',
        marginLeft: '15px',
        maxHeight: '585px',
        minHeight: '500px',
        maxWidth: '32vh',
        minWidth: '23vh'
    },
}));

const UserPage = ({ setOpenModal }) => {
    const classes = UseStyles();

    const handleOpenDetailsPage = () => {
        setOpenModal(false);
    };

    const handleReturnPage = () => {
        setOpenModal(false);
    };

    return (
        <Col lg="9" md="9" >
            <div style={{ marginTop: '75px', borderRadius: '1px', position: 'relative', }}>
                <Card id="ConnectedDriverCard" className={classes.cardStyle} >
                    <CardBody>
                        <Row>
                            <Col lg="12" md="12" >
                                <Row style={{ marginLeft: '-80px' }}>
                                    <Col md={12} sm={12} xs={12} lg={12}>
                                        <Row style={{ marginLeft: '-8px', marginTop: '80px' }}>
                                            {/* <span style={{ float: 'left', marginLeft: '20px', fontFamily: 'LynkcoTypeMedium', fontSize: '15px', color: '#fff' }}>Step One - Identify Vehicle</span> */}
                                            <Col md={12} sm={12} xs={12} lg={12}>
                                                <div style={{ marginTop: '-80px', marginBottom: '-75px', marginLeft: '84px', fontSize: '15px', fontFamily: 'LynkcoTypeMedium', color: '#fff' }}>Select Case Owner</div>
                                                <div>
                                                    <UserTable />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row style={{ marginBottom: '20px', marginTop: '-30px', marginLeft: '76px', marginRight: '10px' }}>
                                            <Col lg="6" md="6">
                                                <span style={{ float: 'left' }}>
                                                    <SearchButton
                                                        placeholder='Go Back'
                                                        // iconProp='true'
                                                        cancelProp='true'
                                                        //   icon={arrowLeft_red}
                                                        handleSearch={handleReturnPage}
                                                    />
                                                </span>
                                            </Col>
                                            <Col lg="6" md="6">
                                                <span style={{ float: 'right', marginRight: '-10px' }}>
                                                    <SearchButton
                                                        placeholder={'Confirm'}
                                                        height='true'
                                                        widthProp='true'
                                                        icon={dots}
                                                        handleConfirmvehicleDetails={handleOpenDetailsPage}
                                                    />
                                                </span>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </Col>
    );
};

export default UserPage;