import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PositionTracking from 'src/pages/vsp/position-tracking-page';
import VehicleDetailsPage from 'src/components/vehicleDetails/VehicleDetailsPage';
import ConnectedDrivers from 'src/components/vehicleDetails/ConnectedDrivers';
import SVTMainComponent from 'src/pages/svt-page/SVTMainComponent';
import Chat from 'src/containers/Chat';
import CommentsPage from 'src/pages/comments-page/CommentsPage';

function TabPanel(props) {
  const { children, value, index, vehiclePosition, closeHandleDrawer, isExpanded, vin, shouldRenderMap, handleChangeVIN,
    mapSpinner,
    setAddressInput,
    addressInput,
    comments,
    getComments,
    dataLoaded,
    ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && value === 0 && (
        <PositionTracking
          vin={vin}
          value={2}
          vehiclePosition={vehiclePosition}
          mapSpinner={mapSpinner}
          setAddressInput={setAddressInput}
          addressInput={addressInput}
        />
      )}
      {value === index && value === 1 && (
        <VehicleDetailsPage value={value} vin={vin} />
      )}
      {value === index && value === 2 && (
        <ConnectedDrivers value={value} vin={vin} />
      )}
      {value === index && value === 3 && (
        <SVTMainComponent selectedVIN={vin} valueVIN={value} />
      )}
      {value === index && value === 4 && (
        <Chat vin={vin} valueVIN={value} comments={comments} getComments={getComments} dataLoaded={dataLoaded} />
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  vehiclePosition: PropTypes.object,
  closeHandleDrawer: PropTypes.func.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginLeft: '-10px',
    marginRight: '5px',
  },

  panel: {
    // marginTop: '1px',
    position: "relative",
    height: "100%",
    paddingLeft: '5px',
    backgroundColor: "transparent",
  },
  panel1: {
    marginTop: '-25px',
    position: "relative",
    height: "100%",
    paddingLeft: '10px',
    backgroundColor: "transparent",
  },
  tabBar: {
    marginLeft: '-25px',
    marginRight: '15px',
    minWidth: '10vw',
    maxWidth: '40vw',
    // fontSize: '14px'
  },
  appBar: {
    backgroundColor: "transparent",
    position: 'fixed',
    marginTop: '-8px',
    marginLeft: '11px',
    zIndex: '5',
    width: '47vw',
    borderTopRightRadius: '15px'
  }
}));

const StyledTabs = withStyles({
  indicator: {
    backgroundColor: '#1EF1C6',
    height: '3px',
    maxWidth: '6.5vw',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    marginLeft: '1.8vw',
    // marginRight: '-50px'
  },

})(Tabs);

export default function VehicleDetailsTabs(props) {
  const { vehiclePosition, shouldRenderMap, closeHandleDrawer, isExpanded, vin, comments, getComments, dataLoaded, mapSpinner, handleChangeVIN } = props
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if(newValue == 0) {
      handleChangeVIN();
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <StyledTabs value={value} onChange={handleChange} aria-label="simple tabs example" >
          <Tab label="MAP" {...a11yProps(0)} className={classes.tabBar} />
          <Tab label="Vehicle" {...a11yProps(1)} className={classes.tabBar} />
          <Tab label="Drivers" {...a11yProps(2)} className={classes.tabBar} />
          <Tab label="SVT" {...a11yProps(3)} className={classes.tabBar} />
          <Tab label="Comments" {...a11yProps(4)} className={classes.tabBar} />
        </StyledTabs>
      </AppBar>
      <TabPanel 
        vehiclePosition={vehiclePosition} 
        shouldRenderMap={shouldRenderMap} 
        vin={vin} 
        closeHandleDrawer={closeHandleDrawer} 
        value={value} 
        index={0} 
        className={classes.panel1} 
        isExpanded={isExpanded} 
        mapSpinner={mapSpinner} 
        handleChangeVIN={handleChangeVIN}
      >
        Map
      </TabPanel>
      <TabPanel value={value} vin={vin} index={1} className={classes.panel}>
        Vehicle
      </TabPanel>
      <TabPanel value={value} vin={vin} index={2} className={classes.panel}>
        Drivers
      </TabPanel>
      <TabPanel value={value} vin={vin} index={3} className={classes.panel}>
        SVT
      </TabPanel>
      <TabPanel value={value} vin={vin} comments={comments} getComments={getComments} dataLoaded={dataLoaded} index={4} className={classes.panel}>
        Comments
      </TabPanel>
    </div>
  );
}