import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import Typography from '@material-ui/core/Typography';

const UseStyles = makeStyles({
    gridContainerStyle: {
        marginTop: '-25px',
        marginLeft: '-20px',
    },
    contentStyle: {
        marginLeft: '15px',
        fontSize: '14px',
        color: '#000',
        marginTop: '20px',
        fontFamily: 'LynkcoTypeRegular',
        whiteSpace: 'nowrap',
        fontWeight: 'light',
    }
});

const InfoWindowComponentVSP = (props) => {
    const { heading, country, vehicleUpdatedAt, postalCode, street, handleChange, toggleInfoWindowMarker } = props
    const classes = UseStyles();

    return (
        <Col md={12} lg={12}>
            <div style={{
                marginLeft: '-14px',
                backgroundColor: 'white',
                borderRadius: '1px',
                display: 'flex',
                overflowX: 'hidden',
                overflowY: 'hidden',
                borderRadius: '1px'
            }}>
                <Row>
                    <Col lg="12" md="12" style={{ marginRight: heading.length >= 18 ? '50px' : '10px' }} >
                        <div style={{ marginRight: '-50px' }}>
                            <Grid container className={classes.gridContainerStyle} spacing={1}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Typography className={classes.contentStyle} style={{ fontWeight: 'bold' }} variant="h4" id="tableTitle" component="div">
                                        {heading}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>

                        {street === undefined || street === 'null' ? <></> :
                            <div>
                                <Grid container className={classes.gridContainerStyle} spacing={1}>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Typography className={classes.contentStyle} variant="h4" id="tableTitle" component="div">
                                            {street}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>}

                        {postalCode === undefined || postalCode === '' ? <></> :
                            <div>
                                <Grid container className={classes.gridContainerStyle} spacing={1}>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Typography className={classes.contentStyle} variant="h4" id="tableTitle" component="div">
                                            {postalCode}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        }

                        {country === undefined || country === 'null' ? <></> :
                            <div>
                                <Grid container className={classes.gridContainerStyle} spacing={1}>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Typography className={classes.contentStyle} variant="h4" id="tableTitle" component="div">
                                            {country}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>}

                        {vehicleUpdatedAt === undefined || vehicleUpdatedAt === 'null' ? <></> :
                            <div>
                                <Grid container className={classes.gridContainerStyle} spacing={1}>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Typography className={classes.contentStyle} variant="h4" id="tableTitle" component="div">
                                            {vehicleUpdatedAt}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        }
                        {
                            toggleInfoWindowMarker ?
                                <span style={{ fontFamily: 'LynkcoTypeRegular', color: '#000', cursor: 'pointer', whiteSpace: 'nowrap', fontSize: '12px', cursor: 'pointer' }} onClick={handleChange}>See vehicles</span>
                                :
                                <></>
                        }
                    </Col>
                </Row>
            </div>
        </Col>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(InfoWindowComponentVSP);