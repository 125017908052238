import { createTheme } from '@material-ui/core/styles'
import LynkcoTypeBold from 'src/fonts/LynkcoType-Bold.ttf';
import LynkcoTypeLight from 'src/fonts/LynkcoType-Light.ttf';
import LynkcoTypeMedium from 'src/fonts/LynkcoType-Medium.ttf';
import LynkcoTypeRegular from 'src/fonts/LynkcoType-Regular.ttf';

const lynkcoTypeBold = {
    fontFamily: 'LynkcoTypeBold',
    fontStyle: 'bold',
    fontDisplay: 'swap',
    fontWeight: '600',
    src: `
        local('LynkcoType'),
        local('LynkcoType-Bold'),
        url(${LynkcoTypeBold}) format('truetype')
 `,
    unicodeRange:
        'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
const lynkcoTypeLight = {
    fontFamily: 'LynkcoTypeLight',
    fontWeight: '600',
    src: `
      local('LynkcoTypeLight'),
      local('LynkcoType-Light'),
      url(${LynkcoTypeLight}) format('truetype')
      `,
    unicodeRange:
        'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
const lynkcoTypeMedium = {
    fontFamily: 'LynkcoTypeMedium',
    fontWeight: 'normal',
    src: `
      local('LynkcoTypeMedium'),
      local('LynkcoType-Medium'),
      url(${LynkcoTypeMedium}) format('truetype')
      `,
    unicodeRange:
        'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
const lynkcoTypeRegular = {
    fontFamily: 'LynkcoTypeRegular',
    fontWeight: 'normal',
    src: `
      local('LynkcoType'),
      local('LynkcoType-Regular'),
      url(${LynkcoTypeRegular}) format('truetype')
      `,
    unicodeRange:
        'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};



const themeStyle = createTheme({

    //     palette: {
    //        type: mode,
    //    },

    typography: {

        fontFamily: ['LynkcoTypeRegular', 'LynkcoTypeMedium', 'LynkcoTypeBold', 'LynkcoTypeLight',].join(','),
        h1: {
            fontFamily: '"LynkcoTypeRegular", "LynkcoTypeMedium", "LynkcoTypeBold", "LynkcoTypeLight"',
            fontWeight: 500,
            fontSize: 35,
            letterSpacing: '-0.24px'
        },
        h2: {
            fontFamily: '"LynkcoTypeRegular", "LynkcoTypeMedium", "LynkcoTypeBold", "LynkcoTypeLight"',
            fontWeight: 500,
            fontSize: 29,
            letterSpacing: '-0.24px'
        },
        h3: {
            fontFamily: '"LynkcoTypeRegular", "LynkcoTypeMedium", "LynkcoTypeBold", "LynkcoTypeLight"',
            fontWeight: 500,
            fontSize: 24,
            letterSpacing: '-0.06px'
        },
        h4: {
            fontFamily: '"LynkcoTypeRegular", "LynkcoTypeMedium", "LynkcoTypeBold", "LynkcoTypeLight"',
            fontWeight: 500,
            fontSize: 20,
            letterSpacing: '-0.06px'
        },
        h5: {
            fontFamily: '"LynkcoTypeRegular", "LynkcoTypeMedium", "LynkcoTypeBold", "LynkcoTypeLight"',
            fontWeight: 500,
            fontSize: 16,
            letterSpacing: '-0.05px'
        },
        h6: {
            fontFamily: '"LynkcoTypeRegular", "LynkcoTypeMedium", "LynkcoTypeBold", "LynkcoTypeLight"',
            fontWeight: 500,
            fontSize: 14,
            letterSpacing: '-0.05px'
        },
        overline: {
            fontWeight: 500
        }
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [lynkcoTypeRegular, lynkcoTypeMedium, lynkcoTypeBold, lynkcoTypeLight],
            },
        },
        MuiPaper: {
            root: {
                color: "rgb(243 238 238)",
                transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                backgroundColor: "rgb(19 19 19 / 90%)",
            }
        },
        MuiIconButton: {
            root: {
                color: "#fff",
                '&.Mui-disabled': {
                    color: "#fff",
                    opacity: 0.5,
                },
            },
            label: {
                width: '75%',
                marginTop: '-4px',
            }
        },
        MuiInputLabel: {
            root: {
                color: "#fff",
                transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                backgroundColor: "rgb(19 19 19 /90%)",
            }
        },
        MuiInputBase: {
            root: {
                color: "#fff",
                // transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                // backgroundColor: "rgb(200 200 19 / 65%)",
                borderColor: "fff",
                fontFamily: 'LynkcoTypeLight'
            }
        },
        MuiSelect: {
            icon: {
                color: "#8a8a8a",
                width: '20px'
            }
        },
        MuiOutlinedInput: {
            notchedOutline: {
                borderColor: "#fff",
            },
            root: {
                '&$focused $notchedOutline': {
                    borderColor: '#1EF1C6',
                    borderWidth: 1,
                }
            }
        },

        MuiFormLabel: {
            root: {

                '&.Mui-focused': {
                    color: "#1EF1C6",
                },
                fontFamily: 'LynkcoTypeLight'
            },
        },
    }
})

export default themeStyle;

