import React, { useState, useEffect, useCallback } from "react";
import { GoogleMap, InfoWindow, Marker, Autocomplete } from "@react-google-maps/api";
import carImage from 'src/assets/img/vehicles/carImage.png';
import carImageGreen from 'src/assets/img/vehicles/carImage_Green.png';
import homeImage from 'src/assets/img/vehicles/homeImage.png';
import homeImageGreen from 'src/assets/img/vehicles/homeImage_Green.png';
import mapBlackVehicle from 'src/assets/img/vehicles/mapBlackVehicle.png';
import mapBlueVehicle from 'src/assets/img/vehicles/mapBlueVehicle.png';
import mccHome from 'src/assets/img/vehicles/mccHome.png';
import mccVehicle from 'src/assets/img/vehicles/mccVehicle.png';
import { Card, Row, Col } from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import InfoWindowComponentVSP from "./infoWindowComponentVSP";
import { GetVSPMapClosestToMCC } from "src/services/connectivity-http.service";
import { styles } from 'src/utils/MapStyle';
import SearchBar from 'src/components/buttons/search-icon-button.component';
import "./styles.css";
import VehiclePageDialog from "src/components/form-elements/vehiclePage-dialog.component";
import { FormmatDateMap } from 'src/utils/DateFormatter';
import PuffLoader from "react-spinners/PuffLoader";
import { GetFleets } from 'src/services/fleet-http.service';
import { QueryVehiclePositionForMapView } from 'src/services/vehicle-pages-http.service';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';

const UseStyles = makeStyles({
    heading: {
        "&.MuiTypography-root": {
            color: "#fff",
            fontFamily: 'LynkcoTypeLight,',
            fontSize: '15px'
        }
    },
    tooltipImg: {
        flexDirection: 'row',
        flexWrap: 'noWrap',
        marginTop: '2px',
    },
    mapContainerStyle: {
        height: "10vh",
        marginRight: '-1vw',
        marginLeft: '-0.9vw'
    },
    image: {
        cursor: 'pointer',
        marginTop: '-1px',
    }
});

const MapVSP = (props) => {
    const { userOrg, userMarketIds, value, data, vin, showSVTMap, addressInput, mapSpinner, setAddressInput, setVehicleDetails, setShowSVTMap, handleChangeVIN, setComments, setWorkOrderStatus, valueVIN, selectedVIN, setSelectedVIN, setSelectedVINMap } = props
    const classes = UseStyles();
    const [activeMarker, setActiveMarker] = useState(null);
    const [markers, setMarkers] = useState([]);
    const [map, setMap] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [message, setMessage] = useState('');
    const [searchResult, setSearchResult] = useState('');
    const [derivedLatLng, setDerivedLatLng] = useState({ lat: 57.71089814294498, lng: 11.945829642768519 });
    const [openCarIcon, setOpenCarIcon] = useState(false);
    const [openHomeIcon, setOpenHomeIcon] = useState(false);

    const [openHomeIconToggle, setOpenHomeIconToggle] = useState(false);
    const [openCarIconToggle, setOpenCarIconToggle] = useState(false);

    const [toggleHomeButton, setToggleHomeButton] = useState(true);
    const [toggleCarButton, setToggleCarButton] = useState(true);

    const imgs = [carImage, carImageGreen];
    const imgsHome = [homeImage, homeImageGreen];
    const [carImg, setCarImg] = useState(imgs[0]);
    const [homeImg, setHomeImg] = useState(imgsHome[0]);
    const [vehiclePosition, setVeiclePosition] = useState({});
    const [mapSpinnerSVT, setMapSpinner] = useState(false);

    useEffect(() => {
        handleTab();
    }, [value, data, vin, setShowSVTMap]);

    useEffect(() => {
        if (valueVIN == 1) {
            handleSVTMap(selectedVIN || addressInput);
            setSelectedVIN();
            setAddressInput(selectedVIN);
            setSelectedVIN(selectedVIN);
            setSelectedVINMap(selectedVIN || addressInput)
        }
    }, [value, data, vin, setShowSVTMap], valueVIN);


    useEffect(() => {
        if (map) {
            if (markers.length > 1) {
                const bounds = new window.google.maps.LatLngBounds();
                markers?.map(marker => {
                    bounds.extend({ lat: parseFloat(marker.position.lat), lng: parseFloat(marker.position.lng) });
                });
                map.fitBounds(bounds);
            }
            map.setOptions({
                disableDefaultUI: true,
                zoomControl: false,
                styles: styles
            });
        }
    }, [map, markers]);

    const addLocation = (location) => {
        setMarkers(location)
    }

    const handleSVTMap = (vin) => {
        setShowSVTMap(true);
        if (showSVTMap) {
            setMapSpinner(true);
            handleChangeVIN();
            let fleetIds = [];
            let marketIds = userMarketIds;
            vin = addressInput.trim();

            let vehiclePositionInstance = {
                "latitude": 0,
                "longitude": 0,
                "odometer": "",
                "color": "",
                "street": "",
                "postalCode": "",
                "city": "",
                "country": "",
            };

            GetFleets(userOrg.id)
                .then(data => {
                    if (data && Array.isArray(data)) {
                        data?.map(item => {
                            fleetIds.push(item.id);
                        })

                        QueryVehiclePositionForMapView(vin, fleetIds, marketIds)
                            .then(data => {
                                if (data?.ok == true) {
                                    vehiclePositionInstance.vin = data.vehiclePosition.vin
                                    vehiclePositionInstance.latitude = data.vehiclePosition.latitude
                                    vehiclePositionInstance.longitude = data.vehiclePosition.longitude
                                    vehiclePositionInstance.vehicleUpdatedAt = data.vehiclePosition.vehicleUpdatedAt
                                    vehiclePositionInstance.color = data.vehiclePosition.color
                                    vehiclePositionInstance.odometer = data.vehiclePosition.odometer
                                    vehiclePositionInstance.street = data.vehiclePosition.addressData.street
                                    vehiclePositionInstance.postalCode = data.vehiclePosition.addressData.postalCode
                                    vehiclePositionInstance.city = data.vehiclePosition.addressData.city
                                    vehiclePositionInstance.country = data.vehiclePosition.addressData.country
                                    setVeiclePosition(vehiclePositionInstance);
                                    setShowSVTMap(true);
                                    setMapSpinner(false);

                                    let firstpartVehicle = data.vehiclePosition?.addressData.postalCode === null ? '' : data.vehiclePosition?.addressData.postalCode;
                                    let secondpartVehicle = data.vehiclePosition?.addressData.city === null ? '' : data.vehiclePosition?.addressData.city;
                                    const postalCodeValueVehicle = firstpartVehicle + ' ' + secondpartVehicle

                                    let location = [{
                                        icon: data.vehiclePosition.color === 'Blue' ? mapBlueVehicle : mapBlackVehicle, type: 1, vin: data.vehiclePosition.vin, position: { lat: data.vehiclePosition?.latitude, lng: data.vehiclePosition?.longitude }, street: data.vehiclePosition?.addressData.street, postalCode: postalCodeValueVehicle, country: data.vehiclePosition?.addressData.country, vehicleUpdatedAt: FormmatDateMap(data.vehiclePosition.vehicleUpdatedAt), heading: 'Car Position',
                                    }]
                                    addLocation(location);
                                    setDerivedLatLng({ lat: data.vehiclePosition?.latitude, lng: data.vehiclePosition?.longitude })

                                } else {
                                    setMapSpinner(false);
                                    setAddressInput('');
                                    setDerivedLatLng({ lat: 57.71089814294498, lng: 11.945829642768519 });
                                };
                            });
                    };
                });
        };
    };

    const handleTab = () => {
        let firstpartVehicle = data?.postalCode === null ? '' : data?.postalCode;
        let secondpartVehicle = data?.city === null ? '' : data?.city;
        const postalCodeValueVehicle = firstpartVehicle + ' ' + secondpartVehicle
        let location = [{
            icon: data.color === 'Blue' ? mapBlueVehicle : mapBlackVehicle, type: 1, vin: vin, position: { lat: data?.latitude, lng: data?.longitude }, street: data?.street, postalCode: postalCodeValueVehicle, country: data?.country, vehicleUpdatedAt: FormmatDateMap(data.vehicleUpdatedAt), heading: 'Car Position',
        }]
        addLocation(location);
        setDerivedLatLng({ lat: data?.latitude, lng: data?.longitude });
    }
    const handleMCCPosition = (type) => {
        if (type === 2) {
            setToggleHomeButton(!toggleHomeButton);
            setToggleCarButton(true);
            let vspType = 'home'
            GetVSPMapClosestToMCC(vin, vspType).then(data => {
                setOpenHomeIcon(true)
                setHomeImg(imgsHome[1]);
                setCarImg(imgs[0]);
                setOpenCarIcon(false);
                setOpenHomeIconToggle(true);
                setOpenCarIconToggle(false);

                if (data && toggleHomeButton) {
                    if (data.ok == true) {
                        let firstpart = data.mapData?.addressData?.postalCode === null ? '' : data.mapData?.addressData?.postalCode;
                        let secondpart = data.mapData?.addressData?.city === null ? '' : data.mapData?.addressData?.city;
                        const postalCodeValue = firstpart + ' ' + secondpart

                        let firstpartVehicle = data.mapData?.mccData?.postalCode === null ? '' : data.mapData?.mccData?.postalCode;
                        let secondpartVehicle = data.mapData?.mccData?.city === null ? '' : data.mapData?.mccData?.city;
                        const postalCodeValueVehicle = firstpartVehicle + ' ' + secondpartVehicle

                        addLocation([
                            {
                                icon: mccHome, type, vin: vin, position: { lat: parseFloat(data.mapData?.addressData?.latitude), lng: parseFloat(data.mapData?.addressData?.longitude) }, street: data.mapData?.addressData?.street, postalCode: postalCodeValue.trim(), country: data.mapData?.addressData?.country, vehicleUpdatedAt: '', heading: 'Home Address'
                            },
                            {
                                icon: mccVehicle, type, vin: vin, position: { lat: parseFloat(data.mapData?.mccData?.latitude), lng: parseFloat(data.mapData?.mccData?.longitude) }, street: data.mapData?.mccData?.street, postalCode: postalCodeValueVehicle, country: data.mapData?.mccData?.country, vehicleUpdatedAt: '', heading: data.mapData?.mccData?.name

                            }
                        ])
                    }
                } else {
                    handleTab();
                    setOpenHomeIcon(false)
                    setHomeImg(imgsHome[0]);
                    setCarImg(imgs[0]);
                    setOpenCarIcon(false);
                    setOpenHomeIconToggle(false);
                    setOpenCarIconToggle(false);
                }
            })
        }

        if (type === 3) {
            setToggleCarButton(!toggleCarButton);
            setToggleHomeButton(true);
            let vspType = 'vehicle'
            GetVSPMapClosestToMCC(vin, vspType).then(item => {

                setCarImg(imgs[1]);
                setOpenCarIcon(true);

                setOpenHomeIcon(false)
                setHomeImg(imgsHome[0]);

                setOpenCarIconToggle(true);
                setOpenHomeIconToggle(false);
                if (item && toggleCarButton) {
                    if (item.ok == true) {
                        let firstpart = item.mapData?.mccData?.postalCode === null ? '' : item.mapData?.mccData?.postalCode;
                        let secondpart = item.mapData?.mccData?.city === null ? '' : item.mapData?.mccData?.city;
                        const postalCodeValue = firstpart + ' ' + secondpart

                        let firstpartVehicle = data?.postalCode === null ? '' : data?.postalCode;
                        let secondpartVehicle = data?.city === null ? '' : data?.city;
                        const postalCodeValueVehicle = firstpartVehicle + ' ' + secondpartVehicle
                        addLocation([
                            {
                                icon: mccVehicle, type, vin: vin, position: { lat: parseFloat(item.mapData?.mccData?.latitude), lng: parseFloat(item.mapData?.mccData?.longitude) }, street: item.mapData?.mccData?.street, postalCode: postalCodeValue, country: item.mapData?.mccData?.country, vehicleUpdatedAt: '', heading: item.mapData?.mccData?.name
                            },
                            {
                                icon: data.color === 'Blue' ? mapBlueVehicle : mapBlackVehicle, type: 1, vin: vin, position: { lat: data.latitude, lng: data.longitude }, street: data?.street, postalCode: postalCodeValueVehicle, country: data?.country, vehicleUpdatedAt: FormmatDateMap(data.vehicleUpdatedAt), heading: 'Car Position'
                            }
                        ])
                    }
                } else {
                    handleTab();
                    setCarImg(imgs[0]);
                    setOpenCarIcon(false);

                    setOpenHomeIcon(false)
                    setHomeImg(imgsHome[0]);

                    setOpenCarIconToggle(false);
                    setOpenHomeIconToggle(false);

                }
            })
        }
    }

    const handleActiveMarker = (marker) => {
        setActiveMarker(marker);
    };

    const onLoad = useCallback((map) => setMap(map), []);

    const handleSearch = (address, type) => {
        if (type === 4) {
            if (address != undefined) {

                let vspType = 'address'

                GetVSPMapClosestToMCC(vin, vspType, address).then(item => {
                    if (item) {
                        setCarImg(imgs[0]);
                        setOpenCarIcon(false);
                        setOpenHomeIcon(false)
                        setHomeImg(imgsHome[0]);
                        setOpenCarIconToggle(false);
                        setOpenHomeIconToggle(false);

                        setOpenHomeIcon(false)
                        setHomeImg(imgsHome[0]);
                        setCarImg(imgs[0]);
                        setOpenCarIcon(false);
                        setOpenHomeIconToggle(false);
                        setOpenCarIconToggle(false);

                        if (item.ok == true) {
                            let firstpart = item.mapData?.addressData?.postalCode === null ? '' : item.mapData?.addressData?.postalCode;
                            let secondpart = item.mapData?.addressData?.city === null ? '' : item.mapData?.addressData?.city;
                            const postalCodeValue = firstpart + ' ' + secondpart

                            let firstpartVehicle = item.mapData?.mccData?.postalCode === null ? '' : item.mapData?.mccData?.postalCode;
                            let secondpartVehicle = item.mapData?.mccData?.city === null ? '' : item.mapData?.mccData?.city;
                            const postalCodeValueVehicle = firstpartVehicle + ' ' + secondpartVehicle
                            addLocation([
                                {
                                    icon: '', type: 4, vin: vin, position: { lat: parseFloat(item.mapData?.addressData?.latitude), lng: parseFloat(item.mapData?.addressData?.longitude) }, street: item.mapData?.addressData?.street, postalCode: postalCodeValue, country: item.mapData?.addressData?.country, vehicleUpdatedAt: '', heading: 'Address'
                                },
                                {
                                    icon: mccVehicle, type: 4, vin: vin, position: { lat: parseFloat(item.mapData?.mccData?.latitude), lng: parseFloat(item.mapData?.mccData?.longitude) }, street: item.mapData?.mccData?.street, postalCode: postalCodeValueVehicle, country: item.mapData?.mccData?.country, vehicleUpdatedAt: '', heading: item?.mapData?.mccData?.name
                                }
                            ]);
                        };
                    };
                    if (item.ok == false) {
                        setMessage('Location not found!');
                        setDialogMessage('Invalid location, please try again')
                        setOpenDialog(true);
                    };
                });
            };
        };
    };

    const handleSearchInput = (event) => {
        setAddressInput(event.target.value);
        console.log(event.target.value);
        console.log(event.target.value.length);
    };

    const handleVINInput = (event) => {
        setAddressInput(event.target.value);
        setSelectedVINMap(event.target.value);
        console.log(event.target.value);
        console.log(event.target.value.length);
        if (event.target.value.length === 0) {
            setAddressInput('');
            setVehicleDetails({});
            setMarkers([]);
            setComments([]);
            setWorkOrderStatus({});
        };
    };

    const textFieldKeypress = (e) => {
        if (e.key === 'Enter') {
            if (searchResult !== 'undefined') {
                const address = searchResult?.getPlace()?.formatted_address
                handleSearch(address, 4);
            }
        }
    };

    const textFieldKeypressVINInput = (e) => {
        if (e.key === 'Enter') {
            if (addressInput !== 'undefined') {
                handleSVTMap();
            }
        }
    };

    const onPlaceChanged = () => {
        if (searchResult != null) {
            const address = searchResult.getPlace().formatted_address
            handleSearch(address, 4)
        }
    };

    const onLoadAddress = (autocomplete) => {
        setSearchResult(autocomplete);
    };

    const handleDialogOk = () => {
        setOpenDialog(false);
        setMapSpinner(false);
    };

    const handleCloseCarIcon = () => {
        setCarImg(imgs[0]);
        setOpenCarIcon(false)
    };

    const handleOpenCarIcon = () => {
        setCarImg(imgs[1]);
        setOpenCarIcon(true)
    };

    const handleCloseHomeIcon = () => {
        setHomeImg(imgsHome[0]);
        setOpenHomeIcon(false)
    };

    const handleOpenHomeIcon = () => {
        setHomeImg(imgsHome[1]);
        setOpenHomeIcon(true)
    };

    return (
        <>
            {
                mapSpinner || mapSpinnerSVT ?
                    <div style={{ position: 'absolute', width: '100.4%', height: '710px', marginLeft: '-15px', alignItems: 'center', backgroundImage: 'linear-gradient(135deg, #000000, #272727 90%)', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px', boxShadow: '3px 5px 6px #000000', marginTop: '-26px', display: 'flex', justifyContent: 'center', }}>

                        <div style={{ fontSize: '25px', color: '#fff', textAlign: 'center', fontFamily: 'LynkcoTypeRegular', whiteSpace: 'nowrap' }}>
                            <PuffLoader color="#1EF1C6" size={200} />
                        </div>
                    </div>
                    :
                    <>
                        {data?.latitude === null || data?.longitude === null ?
                            <div style={{ position: 'absolute', width: '100.4%', height: '710px', marginLeft: '-15px', alignItems: 'center', backgroundImage: 'linear-gradient(135deg, #000000, #272727 90%)', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px', boxShadow: '3px 5px 6px #000000', marginTop: '-26px', display: 'flex', justifyContent: 'center', }}>
                                <div style={{ fontSize: '25px', color: '#fff', textAlign: 'center', fontFamily: 'LynkcoTypeRegular', whiteSpace: 'nowrap' }}>
                                    Vehicle Position Not Available
                                </div>
                            </div>
                            :
                            <GoogleMap
                                onLoad={onLoad}
                                onClick={() => setActiveMarker(null)}
                                mapContainerStyle={{ height: props.height, marginRight: '-20px', marginLeft: '-15px', marginTop: '-19px', borderRadius: '1px', marginBottom: '-15px', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px' }}
                                zoom={13}
                                zoomControl={true}
                                center={derivedLatLng}
                            >
                                <VehiclePageDialog
                                    dialogue='true'
                                    open={openDialog}
                                    message={dialogMessage}
                                    handleDialogOk={handleDialogOk}
                                    title={message}
                                />
                                {
                                    showSVTMap ?
                                        <table style={{ width: '100%', color: 'white' }}>
                                            <tr>
                                                <td style={{ float: 'left', }}>
                                                    <div style={{ width: '450%', marginBottom: '-60px' }}>
                                                        <Autocomplete
                                                            onPlaceChanged={onPlaceChanged}
                                                            onLoad={onLoadAddress}
                                                            style={{ backgroundColor: 'red' }}
                                                        >
                                                            <SearchBar
                                                                handleSearch={handleSearch}
                                                                handleSearchInput={handleSearchInput}
                                                                onKeyPress={textFieldKeypress}
                                                                placeholder={'Search on map'}

                                                            />
                                                        </Autocomplete>
                                                    </div>
                                                    <br />
                                                </td>
                                                <td style={{ float: 'right', }}><span style={{ color: 'darkgray' }}>
                                                    <div style={{ width: '450%', marginBottom: '-100px' }}>
                                                        <SearchBar
                                                            handleSearch={handleSVTMap}
                                                            handleSearchInput={handleVINInput}
                                                            onKeyPress={textFieldKeypressVINInput}
                                                            placeholder={'License or Registration Number'}
                                                            value={addressInput}
                                                        />
                                                    </div>
                                                </span></td>
                                            </tr>
                                        </table>
                                        :
                                        <div style={{ width: '140%', marginTop: '15px', marginLeft: '-5px', marginBottom: '-11px' }}>
                                            <Autocomplete
                                                onPlaceChanged={onPlaceChanged}
                                                onLoad={onLoadAddress}
                                                style={{ backgroundColor: 'red', zIndex: 1000 }}

                                            >
                                                <SearchBar
                                                    handleSearch={handleSearch}
                                                    handleSearchInput={handleSearchInput}
                                                    onKeyPress={textFieldKeypress}
                                                    placeholder={'Search on map'}

                                                />
                                            </Autocomplete>
                                        </div>
                                }
                                <div style={{ marginRight: '45px', flex: '1 1 180%', marginTop: '-50px', float: 'right' }}>
                                    <Row>
                                        <Col>
                                            <Card style={{ marginLeft: '-25px', marginTop: '5px', backgroundColor: 'transparent', width: '41px', borderRadius: '1px', position: 'absolute' }}>

                                                <Tooltip
                                                    className={classes.tooltipImg}
                                                    title="MCC nearest home"
                                                    onOpen={handleOpenHomeIcon}
                                                    onClose={openHomeIconToggle ? null : handleCloseHomeIcon}
                                                    // open={ openHomeIcon}
                                                    onClick={() => handleMCCPosition(2)}
                                                >
                                                    <img
                                                        alt="Card image cap"
                                                        src={homeImg}
                                                        className={classes.image}
                                                    />
                                                </Tooltip>
                                            </Card>
                                        </Col>
                                        <Col>
                                            <Card style={{ marginLeft: '-5px', marginTop: '5px', backgroundColor: 'transparent', width: '40.5px', borderRadius: '1px', position: 'absolute' }}>
                                                <Tooltip
                                                    className={classes.tooltipImg}
                                                    title="MCC nearest car"
                                                    onOpen={handleOpenCarIcon}
                                                    onClose={openCarIconToggle ? null : handleCloseCarIcon}
                                                    // open={openCarIcon}
                                                    onClick={() => handleMCCPosition(3)}
                                                >
                                                    <img
                                                        alt="Card image cap"
                                                        src={carImg}
                                                        className={classes.image}
                                                    />
                                                </Tooltip>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>

                                {markers.map((value, index) => (
                                    <Marker
                                        key={index}
                                        position={value.position}
                                        onClick={() => handleActiveMarker(index)}
                                        icon={value.icon}
                                    >
                                        {activeMarker === index ? (
                                            <InfoWindow
                                                onCloseClick={() => setActiveMarker(null)}
                                            >
                                                <InfoWindowComponentVSP
                                                    heading={value.heading}
                                                    street={value.street}
                                                    postalCode={value.postalCode}
                                                    country={value.country}
                                                    vehicleUpdatedAt={value.vehicleUpdatedAt}
                                                />
                                            </InfoWindow>
                                        ) : null}
                                    </Marker>
                                )
                                )};
                            </GoogleMap>
                        };
                    </>
            };
        </>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(MapVSP);