import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const SidebarLink = ({
  title, icon, newLink, route, onClick,
}) => (
  <li>
    <SidebarNavLink
      to={route}
      onClick={onClick}
      activeClassName="active"
    >
      {/* {icon ? <img src={'../assets/icons/' + icon} height="23" style={{ marginTop: '-13px', marginLeft: '-4px', marginRight: '10px' }} /> : ''} */}
      <div className="sidebarFontsize" style={{ textWrap: 'nowrap', fontFamily: 'LynkcoTypeMedium' }}>
        {title}

      </div>
      {/* {icon ? <SidebarLinkIcon className={`lnr lnr-${icon}`} /> : ''} */}

      {/* <SidebarLinkTitle>
        {title}
        {newLink ? (
          <NewBadge bg="custom">
            <span>New</span>
          </NewBadge>
        ) : ''}
      </SidebarLinkTitle> */}
    </SidebarNavLink>
  </li>
);

SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  newLink: PropTypes.bool,
  route: PropTypes.string,
  onClick: PropTypes.func,
};

SidebarLink.defaultProps = {
  icon: {},
  newLink: false,
  route: '/',
  onClick: () => { },
};

export default SidebarLink;

// region STYLES

export const SidebarNavLink = styled(NavLink)`
  height: 80px;
  width: 200px;
  transition: all 0.3s;
  position: relative;
  cursor: pointer;
  display: flex;
  margin-left: 110px;
  margin-top: -10px;
  // padding: 35px 20px 33px 20px;
  padding-left: 10px;
  padding-top: 35px;
  padding-bottom: 20px;
  padding-right: 10px;
  overflow: hidden;
  background: transparent;
  border: none;
  color: #dddddd;
  text-align: left;
  font-size: 14px;
  

  &.active {
    text-decoration: none;
    background-color: #000;
    // border-left: 4px solid #1EF1C6;
    // border-bottom: 3px solid #04a6e9;
    color: #dddddd;
    // border-top-right-radius: 13px;
    // border-top-left-radius: 13px;
    // border-bottom-right-radius: 13px;
    // border-bottom-left-radius: 13px;
    width: 13vw;
    border-width: 3px;
    border-style: solid;
    border-image: linear-gradient(to right, #5D1EF1 0%, #1EF1C6 100%) 0 0 100% 0;

    &:before {
      opacity: 1;
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 2px;
    background: #000;
    opacity: 0;
    transition: all 0.3s;
    left: 0;
  }

  &:hover {
    text-decoration: none;
    background-color: #000;
    // border-left: 4px solid #1EF1C6;
    border-bottom: 3px solid #1EF1C6;
    color: #dddddd;
    // border-top-right-radius: 13px;
    // border-bottom-right-radius: 13px;

    &:before {
      opacity: 1;
    }
  }
  width: 100%;

  @media screen and (min-width: 576px) {
    width: 100%;

    span {
      position: relative;
      animation: none;
      right: 0;
    }
  }
`;

const NewBadge = styled(Badge)`
  width: 26px;
  height: 14px;
  background: #ff4861
  font-size: 8px;
  font-weight: 400;
  padding: 2px;
  line-height: 9px;
  position: relative;
  text-transform: uppercase;
  border-radius: 7px;
  margin-left: 15px;

  span {
    position: absolute;
    top: 3px;
    width: 26px;
    text-align: center;
    left: 0;
  }
`;

export const SidebarLinkTitle = styled.span`
margin-top: -3px;
font-size: 14px;
  line-height: 4px;
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  background: transparent;
  padding-left: 15px;
`;

export const SidebarLinkIcon = styled.span`
  font-size: 23px;
  line-height: 13px;
  color: #787985;
  margin-left: -4px;
  margin-top: -7px;
`;