import React from "react";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Map from 'src/pages/vsp/MapPage';

const UseStyles = makeStyles({
    gridContainerStyle: {
        width: '100%',
        marginTop: '25px',
        
    }
});

const PositionTracking = (props) => {
    const { value, vin, vehiclePosition, mapSpinner, showSVTMap, setAddressInput, addressInput, setVehicleDetails, setShowSVTMap, handleChangeVIN, setComments, setWorkOrderStatus, valueVIN, selectedVIN, setSelectedVIN, setSelectedVINMap } = props
    const classes = UseStyles(props);

    return (
        <div >
        {/* <div style={{ backgroundColor: 'rgb(19 19 19 / 90%)', marginTop: '-770px', marginRight: '1.1vw', marginLeft: '0.4vw' }}> */}

            <Grid container className={classes.gridContainerStyle} >
                <Grid item xs={12} md={12} style={{ backgroundColor: 'transparent', marginTop: value == 1 ? '-754px' : '60px', marginRight: value == 1 ? '1.4vw' : '-0.2vw', marginLeft: value == 1 ? '0.4vw' : '0.6vw,' }}>
                    <Map
                        value={value}
                        vin={vin}
                        // height='720px'
                        height=  {value == 1 ? '720px' : '703px'}
                        data={vehiclePosition}
                        mapSpinner={mapSpinner}
                        showSVTMap={showSVTMap}
                        setAddressInput={setAddressInput}
                        addressInput={addressInput}
                        setVehicleDetails={setVehicleDetails}
                        setShowSVTMap={setShowSVTMap}
                        handleChangeVIN={handleChangeVIN}
                        setComments={setComments}
                        setWorkOrderStatus={setWorkOrderStatus}
                        valueVIN={valueVIN}
                        selectedVIN={selectedVIN}
                        setSelectedVIN={setSelectedVIN}
                        setSelectedVINMap={setSelectedVINMap}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default PositionTracking;