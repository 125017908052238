import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Scrollbar from 'src/containers/LayoutNew/scrollBar/ScrollBar';
import { CurrentUserProps, ContactsProps } from 'src/prop-types/ChatProps';
import ChatBubble from 'src/containers/Chat/components/ChatBubble';
import ChatField from 'src/containers/Chat/components/ChatField';
import LoadingImagePlaceHolder from 'src/components/placeholder/loadingimageplaceholder';
import { Row, Col } from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";

const UseStyles = makeStyles((theme) => ({
  tooltipImg: {
    flexDirection: 'row',
    flexWrap: 'noWrap',
    backgroundColor: 'transparent',
    padding: '4px',
  },
  root: {
    marginBottom: -theme.spacing(4),
    marginTop: '-70px'
  },
}));

const Chat = ({ vin, comments, getComments, dataLoaded, closeVehicleDetail, showSVTMap, variant }) => {
  const [openContacts, setOpenContacts] = useState(false);
  const messageEl = useRef(null);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    if (messageEl) {
      messageEl.current?.addEventListener('DOMNodeInserted', event => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
      });
    }
  }, []);

  const classes = UseStyles();

  const onOpenContacts = () => {
    setOpenContacts(state => !state);
  };

  return (
    <ChatContainer
      open={openContacts}
      onClick={openContacts ? onOpenContacts : null}
      role="presentation"
      variant={variant}
    >
      <ChatDialog>
        <Col lg="12" md="12" >

          <Row style={{ marginTop: '12px', marginBottom: '25px' }}>
            <Col lg="8" md="8">
              <div style={{ fontFamily: "LynkcoTypeMedium", fontSize: '14px', marginBottom: '15px', marginLeft: '20px' }}>
                
              </div>
            </Col>

            <Col lg="4" md="4">
              {/* {
                showSVTMap ? <></> :
                  <Toolbar className={clsx(classes.root)}>
                    <Tooltip

                      onClick={closeVehicleDetail}
                      className={classes.tooltipImg}
                      title="Close"
                      style={{ fontSize: hovered ? '30px' : '22px', color: '#DC1860', cursor: 'pointer', marginLeft: '8vw', marginTop: hovered ? '53px' : '60px' }}>
                      <span
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}
                      >
                        <i className="mr-2 mdi mdi-close" />
                      </span>
                    </Tooltip>
                  </Toolbar>
              } */}
            </Col>
          </Row>
        </Col>
        <Messages ref={messageEl}>
          <ChatMessagesWrap>
            {dataLoaded ? (
              <ChatMessages >
                {comments.map(item =>
                (
                  <ChatBubble
                    key={item.id}
                    contact={item.userId}
                    message={item.comment}
                    date={item.createdDateUtc}
                    active={item.id}
                    vin={vin}
                    getComments={getComments}
                    photo={item.userPhoto}
                    fullName={item.fullName}
                  />
                )
                )}
              </ChatMessages>
            ) : <LoadingImagePlaceHolder />}

          </ChatMessagesWrap>
        </Messages>

        <ChatField vin={vin}
          getComments={getComments}
        />
      </ChatDialog>
    </ChatContainer>
  );
};

Chat.propTypes = {
  currentUser: CurrentUserProps.isRequired,
  contacts: ContactsProps.isRequired,
};

export default Chat;

// region STYLES

const ChatContainer = styled.div`
  height: 652px;
  min-height: 200px;
  display: flex;
  overflow: hidden;
  position: relative;
  // border-radius: 4px; 
  // box-shadow: 3px 5px 6px #000000;
  margin-top: 70px;
  background-color: transparent;
  // background-image: linear-gradient(135deg, #101010 20%, #373737 99%);  

  @media screen and (max-width: 1023px) {

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      opacity: 0;
      z-index: 0;
      transition: opacity 0.3s, z-index 0.3s 0.3s;
      background-color: #999999;
    }
    
    ${props => props.open && `
      &:before {
        opacity: 0.2;
        z-index: 1;
        transition: opacity 0.3s, z-index 0s;
        pointer-events: none;
      }

      .chat__scroll, .chat__text-field {
        pointer-events: none;
      }
    `}
  }
`;

const ChatContactList = styled.div`
  width: 335px;
  height: 100%;
  overflow: hidden;
  transition: transform 0.3s;
  background-color: #232329;

  border-right: 1px solid #33333a;

  @media screen and (max-width: 1023px) {
    max-width: 335px;
    width: 100%;
    transform: translateX(calc(-100% - 1px));
    position: absolute;
    z-index: 1;

    ${props => props.open && `
      transform: translateX(0);
    `}
  }
`;
//////       // ${borderRight(props)}: none;

const ChatContacts = styled.div`
  overflow: auto;
  height: calc(100% - 55px);
  background-color: #212121;
`;

const ChatContactButton = styled.button`
  width: 100%;
  padding: 0;
  border: none;
  background: transparent;
  text-align: Left;

`;

const ChatDialog = styled.div`
  width: calc(100% - 5px);
  overflow: hidden;
  position: relative;

  @media screen and (max-width: 1023px) {
    width: 100%;
  }
`;

const ChatMessagesWrap = styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
  margin-top: -35px
`;

const ChatMessages = styled.div`
  padding: 26px;
  width: 100%;
`;

const ChatEmptyMessages = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;

  p {
    margin: auto;
    border-radius: 15px;
    padding: 5px 20px;
    color: #999999;

    background-color: #232329;

  }
`;


const Messages = styled.div`
  height: 380px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px 10px;
  margin-bottom: 103px;
  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    opacity: 0.3;
    background-color: #131313;

  };
  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border: 1px solid transparent;
    background-color: #404040;
  }
`;


const ChatScroll = styled(Scrollbar)`
  text-align: left;

  .scroll-content {

    &, & > div {
      height: 100%;
    }
  }

  .scrollbar-track {

    &.scrollbar-track-y {
      width: 2px;
      margin-right: 3px;
    }
  }

  .scrollbar-thumb {
    opacity: 0.3;
    width: 5px;
  }
`;

const ChatContactsScroll = styled(Scrollbar)`
  height: 100%;

  .scroll-content {

    &, & > div {
      height: 100%;
    }
  }

  .scrollbar-track {

    &.scrollbar-track-y {
      width: 2px;
      margin-right: 3px;

    }
  }

  .scrollbar-thumb {
    opacity: 0.3;
    width: 5px;
  }
`;

// endregion