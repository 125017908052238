import React from 'react';
// import {  } from 'react-bootstrap';
import { Card, CardBody, Col, Table, Row } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";


// import blue from 'src/shared/img/blue.png';
// import black from 'src/shared/img/black.png';
import './dashboard.css';
// import check from 'src/shared/img/check.png';

const UseStyles = makeStyles((theme) => ({
    dividerStyle: {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        marginLeft: -theme.spacing(1),
        marginRight: -theme.spacing(1),
        marginTop: theme.spacing(6),
        height: '2px',
        marginBottom: '10px'
    },
    gridContainerStyle: {
        marginBottom: -theme.spacing(3.7),
        position: 'sticky',
        marginLeft: '11px',
        marginTop: '-22px'
    },
    drawer: {
        width: 250,
        flexShrink: 0,
        textAlign: 'center',
        top: '19.5%',
        marginTop: '80px',
        marginLeft: '40%',
        position: 'relative',
        height: '20px'
    },
    gridContainerStyle1: {
        width: '100%',
    },
    cardStyle: {
        height: '703px',
        borderRadius: '10px',
        marginRight: '-5px'
        // marginTop: '70px'
    },
    cardStyleLarge: {
        height: '665px',
        borderRadius: '10px',
        marginRight: '-22px'
    },
    imageStyle: {
        marginTop: '60px',
        marginLeft: '15px',
        maxHeight: '585px',
        minHeight: '500px',
        maxWidth: '36vh',
        minWidth: '23vh'
    },
}));

const DataPrimaryDriver = [
    {
        "firstName": "Alexander Scott",
        "lastName": "Crayvenn",
        "phoneNumber": "0046733456890",
        "email": "scott.crayvenn@gmail.com",
    },

]

const DataPrimaryDriver1 = [
    {
        "firstName": "Frank Jr",
        "lastName": "Smith",
        "phoneNumber": "0046708679041",
        "email": "frank.smith.jr@gmail.com",
    },
    {
        "firstName": "Emma Marie",
        "lastName": "Sjöstrand Jansson",
        "phoneNumber": "0046892781801",
        "email": "emma.marie.jansson@gmail.com",
    },
    {
        "firstName": "John Adams",
        "lastName": "Wilkinson",
        "phoneNumber": "0046729093718",
        "email": "john.adams@gmail.com",
    }
]

const DataPrimaryDriver2 = [
    {
        "firstName": "James Stephen",
        "lastName": "Winters",
        "phoneNumber": "0046739561681",
        "email": "john.stephen.winters@gmail.com",
    },

]

const ConnectedDrivers = () => {

    const classes = UseStyles();

    return (
        <div >
            <Row>

                <Col md={12} sm={12} xs={12} lg={12}>
                    <Card id="ConnectedDriverCard" >
                        <CardBody>
                            <Row style={{ marginLeft: '-8px', marginTop: '80px' }}>
                                <Col md={12} sm={12} xs={12} lg={12}>
                                    <div style={{ marginLeft: '18px', marginTop: '-30px', marginBottom: '15px', fontSize: '14px', fontSize: '15px', fontFamily: 'LynkcoTypeMedium'  }}>Customer and Primary Driver</div>
                                    <Table id="admin">

                                        <thead >
                                            <tr >
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th>Phone Number</th>
                                                <th>Email</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {DataPrimaryDriver.map((item) => (
                                                <tr>
                                                    <td>{item.firstName}</td>
                                                    <td>{item.lastName}</td>
                                                    <td>{item.phoneNumber}</td>
                                                    <td>{item.email}</td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </Table>

                                </Col>
                            </Row>
                            <hr  />

                            <Row style={{ marginLeft: '-8px', marginTop: '70px' }}>
                                                                    {/* <div style={{ marginLeft: '18px', marginTop: '-30px', marginBottom: '15px', fontSize: '14px' }}>Digital Key of car is not Shared with anybody</div> */}

                                <Col md={12} sm={12} xs={12} lg={12}>
                                    <div style={{ marginLeft: '18px', marginTop: '-30px', marginBottom: '15px', fontSize: '15px', fontFamily: 'LynkcoTypeMedium' }}>Secondary Drivers</div>
                                    <Table id="admin">

                                        <thead >
                                            <tr >
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th>Phone Number</th>
                                                <th>Email</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {DataPrimaryDriver1.map((item) => (
                                                <tr>
                                                    <td>{item.firstName}</td>
                                                    <td>{item.lastName}</td>
                                                    <td>{item.phoneNumber}</td>
                                                    <td>{item.email}</td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </Table>

                                </Col>
                            </Row>
                            <hr  />

                            <Row style={{ marginLeft: '-8px', marginTop: '70px' }}>
                                {/* <div style={{ marginLeft: '18px', marginTop: '-30px', marginBottom: '15px', fontSize: '14px' }}>Digital Key of car is not Shared with anybody</div> */}

                                <Col md={12} sm={12} xs={12} lg={12}>
                                    <div style={{ marginLeft: '18px', marginTop: '-30px', marginBottom: '15px', fontSize: '14px', fontSize: '15px', fontFamily: 'LynkcoTypeMedium' }}>Car Sharing Borrower</div>
                                    <Table id="admin">

                                        <thead >
                                            <tr >
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th>Phone Number</th>
                                                <th>Email</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {DataPrimaryDriver2.map((item) => (
                                                <tr>
                                                    <td>{item.firstName}</td>
                                                    <td>{item.lastName}</td>
                                                    <td>{item.phoneNumber}</td>
                                                    <td>{item.email}</td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </Table>

                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>

    );
};

export default ConnectedDrivers;