import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Row, Col, CardImg } from 'reactstrap';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import "./styles.css";
import blackVehicle from 'src/assets/img/vehicles/blackVehicle.png';
import blueVehicle from 'src/assets/img/vehicles/blueVehicle.png';
import { FormmatDateMap } from 'src/utils/DateFormatter';
import ToggleComponent from 'src/containers/advancedSearchComponents/ToggleComponent';

const UseStyles = makeStyles({
    gridContainerStyle: {
        marginTop: '-25px',
        marginLeft: '-20px',
    },
    contentStyle: {
        marginLeft: '15px',
        fontSize: '14px',
        color: '#000',
        marginTop: '20px',
        fontFamily: 'LynkcoTypeRegular',
        whiteSpace: 'nowrap',
    }
});

const InfoWindowComponentMap = (props) => {
    const { vehiclePosition } = props
    const classes = UseStyles();
    const [checked, setChecked] = useState(false);

    let firstpartVehicle = vehiclePosition?.postalCode === null ? '' : vehiclePosition?.postalCode;
    let secondpartVehicle = vehiclePosition?.city === null ? '' : vehiclePosition?.city;
    const postalCodeValueVehicle = firstpartVehicle + ' ' + secondpartVehicle;

    const switchHandler = (event) => {
        let value = event.target.checked;
        if (value == true) {
            setChecked(event.target.checked);
            // setQueryParamsFilteredTowbar(true);
            // setDataLoaded(false);
        } else {
            setChecked(event.target.checked);
            // setQueryParamsFilteredTowbar(false);
            // setDataLoaded(false);
        }

        // setChecked(event.target.checked);
    };

    return (
        <Col md={12} lg={12}>
            <div style={{
                marginLeft: '-10px',
                backgroundColor: 'white',
                height: '135px',
                width: '450px',
                overflowX: 'hidden',
                overflowY: 'hidden',
                marginBottom: '-10px',
                marginRight: '-20px'
            }}>
                <Row >
                    <Col md={12} sm={12} xs={12} lg={8} >
                        <Row>
                            <Col lg="6" md="12" >
                                <div>
                                    <CardImg
                                        alt="Card image cap"
                                        src={vehiclePosition?.color == 'Blue' ? blueVehicle : blackVehicle}
                                        style={{ transform: 'scale(.95)', marginLeft: '5px', marginTop: '-2px' }}
                                    />
                                </div>
                            </Col>
                            <Col lg="6" md="12" style={{ marginLeft: '-10px' }} >
                                <div style={{ marginBottom: '5px' }}>
                                    <span style={{ fontFamily: 'LynkcoTypeMedium', color: '#000', cursor: 'pointer', whiteSpace: 'nowrap', fontSize: '12px' }}  >VIN</span><br />
                                    <span style={{ fontFamily: 'LynkcoTypeRegular', color: '#000', cursor: 'pointer', whiteSpace: 'nowrap', fontSize: '12px' }}  >{vehiclePosition.vin}</span>
                                </div>

                                <div style={{ marginBottom: '-10px' }}>
                                    <span style={{ fontFamily: 'LynkcoTypeMedium', color: '#000', cursor: 'pointer', whiteSpace: 'nowrap', fontSize: '12px', }}  >Odometer</span><br />
                                    <span style={{ fontFamily: 'LynkcoTypeRegular', color: '#000', cursor: 'pointer', whiteSpace: 'nowrap', fontSize: '12px', }}  >{vehiclePosition.odometer}</span> <br /><br />

                                </div>

                                <div>
                                    <span style={{ fontFamily: 'LynkcoTypeMedium', color: '#000', cursor: 'pointer', whiteSpace: 'nowrap', fontSize: '12px' }} >Last Updated</span><br />
                                    <span style={{ fontFamily: 'LynkcoTypeRegular', color: '#000', cursor: 'pointer', whiteSpace: 'nowrap', fontSize: '12px' }}  >{FormmatDateMap(vehiclePosition.vehicleUpdatedAt)}</span> <br />
                                </div>
                            </Col>
                        </Row>
                    </Col>

                    <Col md={12} sm={12} xs={12} lg={4} style={{ marginLeft: '-35px' }} >
                        <Row>
                            <Col lg="12" md="12" >
                                <div style={{ marginBottom: '5px' }}>
                                    <span style={{ fontFamily: 'LynkcoTypeMedium', color: '#000', cursor: 'pointer', whiteSpace: 'nowrap', fontSize: '12px' }}  >Street</span> <br />
                                    <span style={{ fontFamily: 'LynkcoTypeRegular', color: '#000', cursor: 'pointer', whiteSpace: 'nowrap', fontSize: '12px' }} >{vehiclePosition.street}</span><br />
                                </div>
                                <div style={{ marginBottom: '-10px' }}>
                                    <span style={{ fontFamily: 'LynkcoTypeMedium', color: '#000', cursor: 'pointer', whiteSpace: 'nowrap', fontSize: '12px' }}  >Post Code</span> <br />
                                    <span style={{ fontFamily: 'LynkcoTypeRegular', color: '#000', cursor: 'pointer', whiteSpace: 'nowrap', fontSize: '12px' }} >{postalCodeValueVehicle}</span><br /><br />
                                </div>
                                <div>
                                    <span style={{ fontFamily: 'LynkcoTypeMedium', color: '#000', cursor: 'pointer', whiteSpace: 'nowrap', fontSize: '12px' }}  >Country</span> <br />
                                    <span style={{ fontFamily: 'LynkcoTypeRegular', color: '#000', cursor: 'pointer', whiteSpace: 'nowrap', fontSize: '12px' }} >{vehiclePosition.country}</span><br />
                                </div>
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </div>
            <hr style={{ backgroundColor: '#212121', marginTop: '-20px' }} />
            <Row style={{ marginTop: '40px' }}>
                <table style={{ width: '100%', color: 'white' }}>
                    <tr>
                        <td ><span style={{ float: 'left', fontSize: '14px', marginTop: '-25px', fontFamily: 'LynkcoTypeMedium', color: '#000', marginLeft: '100px' }}>
                            Mobilized  </span></td>
                        <td >
                            <div style={{ marginLeft: '40px', marginTop: '-40px' }}>
                                <ToggleComponent checked={checked}  onChange={switchHandler}/>
                            </div>

                        </td>
                        <td > <span style={{ float: 'right', fontSize: '14px', marginTop: '-25px', fontFamily: 'LynkcoTypeMedium', color: '#000', marginRight: '90px' }}> Immobilized
                        </span> </td>
                    </tr>

                </table>
            </Row>
        </Col>
    );
}

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(InfoWindowComponentMap);