import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colorBorder } from 'src/containers/LayoutNew/scrollBar/palette';
import SidebarLink, { SidebarLinkTitle } from 'src/containers/LayoutNew/components/sidebar/SidebarLink';
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import { selectUserPermissions, selectUserToken } from 'src/redux/user/user.selectors';
import { connect } from 'react-redux';

const SidebarContent = ({ onClick, collapse, handleClose, setUserIsLoggedIn }) => {
  // const [hovered, setHovered] = useState(false);

  // const UseStyles = makeStyles((theme) => ({
  //   tooltipImg: {
  //     flexDirection: 'row',
  //     flexWrap: 'noWrap',
  //     backgroundColor: 'transparent',
  //     padding: '4px',
  //   },
  //   root: {
  //     marginBottom: hovered ? theme.spacing(1.3) : theme.spacing(2),
  //     // marginTop: hovered ? '-75px' : '-83px',
  //     marginLeft: '-17px'
  //   },
  // }));

  // const classes = UseStyles();

  const handleLogOut = () => {
    localStorage.clear();
    setUserIsLoggedIn(false);
  }

  return (
    <>
      <SidebarContentWrap collapse={collapse}>
        <SidebarBlock collapse={collapse}>

          {/* <Toolbar className={clsx(classes.root)}>
            <Tooltip

              onClick={handleClose}
              className={classes.tooltipImg}
              title="Close"
              style={{ fontSize: hovered ? '30px' : '22px', color: '#DC1860', cursor: 'pointer', float: 'left', marginTop: hovered ? '45px' : '60px' }}>
              <span
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              >
                <i className="mr-2 mdi mdi-close" />
              </span>
            </Tooltip>
          </Toolbar> */}

          <SidebarLink title="Dashboards" icon="Dashboard.png" route="/dashboard" onClick={() => { onClick(); handleClose() }} />

          <SidebarLink title="Cases" icon="caseMenuIcon.png" route="/myOpenCases" onClick={() => { onClick(); handleClose() }} />

          <SidebarLink title="VSP" icon="VSP.png" route="/vsp" onClick={() => { onClick(); handleClose() }} />

          <SidebarLink title="Reports" icon="Report.png" route="/reports" onClick={() => { onClick(); handleClose() }} />

          <SidebarLink title="My Tasks" icon="ToDo.png" route="/todos" onClick={() => { onClick(); handleClose() }} />

          <SidebarLink title="Administration" icon="Administration.png" route="/admin" onClick={() => { onClick(); handleClose() }} />

          {/* </SidebarBlock> */}
          {/* </SidebarContentWrap>

      <SidebarContentWrap collapse={collapse}> */}
          {/* <SidebarBlock collapse={collapse}> */}
          <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
          <SidebarLink title="Log Out" icon="Logout.png" route="/" onClick={() => { onClick(); handleClose(); handleLogOut(); }} />
        </SidebarBlock>
      </SidebarContentWrap>
    </>
  )
}

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  collapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  collapse: false,
  onClick: () => { },
};

const mapStateToProps = state => ({
  userPermissions: selectUserPermissions(state),
  userToken: selectUserToken(state)
});

export default connect(mapStateToProps, null)(SidebarContent)

const SidebarContentWrap = styled.div`
  
  overflow: auto;
  padding-top: 0;
  background: #000;
  // margin-right: 700px;
  border-radius: 13px;
  height: 100vh;
  margin-top: 170px;
  margin-left: -120px;
  width: 26vw;
  & > div:last-child {
    width: 4px !important;

    div {
      transition: height 0.3s;
      opacity: 0.52;
    }
  }

  @media screen and (min-width: 1451px) and (max-width: 1800px) {
    margin-top: 120px;
  }

  @media screen and (min-width: 1401px) and (max-width: 1450px) {
    margin-top: 80px;
  }

  @media screen and (min-width: 1001px) and (max-width: 1275px) {
    margin-left: -150px;
    margin-top: 50px
}
  
  @media screen and (min-width: 576px) {
    ${props => props.collapse && `
      // width: 55px;
      width: 26vw;
      margin-top: 200px;
      margin-bottom: 65px;
      overflow: visible !important;
      transition: width 0.3s;
      
    `}
  }
`;

const SidebarBlock = styled.ul`
  
  margin-bottom: -5px;
  list-style-type: none;

  &:last-child {
    border: none;
  }
  
  @media screen and (min-width: 576px) {

    ${props => props.collapse && `

      & > li > a,
      & > li > button {
        overflow: hidden;
        // width: 55px;
        width: 250px;

        
        /*     padding: 31px 18px 35px;*/

        span:last-of-type {
          opacity: 0;
          transition: 0.3s;
        }
  
        ${SidebarLinkTitle} {
          position: absolute;
          width: 160px;
          left: 70px;
        }
  
        &:hover {
          background: #000;

        }
      }
      
      & > li:hover > a,
      & > li:hover > button {
        width: 235px;
        border-bottom: 3px solid #1EF1C6;
        
        span {
          opacity: 1;
        }
      }
    `}
  }
`;