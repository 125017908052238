import React, { useState, useEffect } from 'react';
import './SlideInAnimation.css';
import LogInForm from 'src/containers/logincomponents/LogInForm';
import { LoginLogo } from 'src/containers/LayoutNew/components/topbar/BasicTopbarComponents';
import { useMsal } from "@azure/msal-react";
import { Field, Form } from 'react-final-form';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import PropTypes from 'prop-types';
import PasswordField from 'src/containers/logincomponents/Password';
import SearchButton from "src/components/buttons/button.component";
import {
    FormGroup,
    FormGroupField,
    FormGroupIcon,
    FormGroupLabel,
} from 'src/containers/logincomponents/FormElements';
import { AccountForgotPassword, LoginForm } from 'src/containers/logincomponents/AccountElements';
import { Col, Row } from 'react-bootstrap';
import { QueryUserByEmailExternalUsers } from 'src/services/user-http.service';
import { SetUserGraphData, SetAdUserDetails, SetUserServiceInfo, SetUserPermission } from 'src/redux/user/user.actions';
import { useSelector, useDispatch } from "react-redux";
import { getUniversalCookies } from 'src/cookies/cookie';
import { getSignedInUserPhoto_Beta } from "src/services/graph.service";
import { connect } from 'react-redux';
import Welcome from "src/pages/welcome-page/welcome-page"
import bgImage from 'src/assets/img/BackgroundImages/BackgroundImageDark.jpg';
import styled from 'styled-components';
import { paddingLeft } from 'src/containers/LayoutNew/scrollBar/directions';
import Content from 'src/containers/Content/TheContent';
import Topbar from 'src/containers/LayoutNew/topbar/Topbar';
import { selectUserGraphData, selectAdUserDetails, selectUserPermissions, selectUserToken } from 'src/redux/user/user.selectors';
import { withStyles } from '@material-ui/core/styles';

import { changeMobileSidebarVisibility, changeSidebarVisibility } from 'src/redux/sidebarActions';
import { changeContentVisibility, changeMobileContentVisibility } from 'src/redux/mainContentActions';
import Sidebar from 'src/containers/LayoutNew/components/sidebar/Sidebar';
import { SidebarData } from "src/containers/Sidebar/SidebarData2";
import { changeThemeToDark, changeThemeToLight } from 'src/redux/themeActions';

const styles = (theme) => ({
    root: {
        display: 'flex',
    },
});

const LoginPage = ({ setUserPermission,
    setGraphData, setUserServiceInfo, theme, props }) => {
    const { instance } = useMsal();

    let emailArray = [{ 'username': 'kristell.haas@arceurope.com', 'name': 'Kristell' }, { 'username': 'vinogradovay@arceurope.fr', 'name': 'Yana' },
    { 'username': 'Ping.Zhang11@geely.com', 'name': 'Ping' }, { 'username': 'qingbo.li@car2data.cn', 'name': 'Qingbo' }, { 'username': 'yalan.wu@car2data.cn', 'name': 'Yalan' },
    { 'username': 'matt.li@hftechglobal.com', 'name': 'Matt' }];
    let userPassword = 'user';

    const [loading, setLoading] = React.useState(true)
    const [userIsLoggedIn, setUserIsLoggedIn] = useState(localStorage.getItem('userIsLoggedIn') == '1')
    const [adUserDetails, SetAdUserDetails] = useState();
    const settings = useSelector((state) => state.settings);
    const [loginStatus, setLoginStatus] = useState(false);
    const [topbarUsername, setTopbarUsername] = useState('')

    const { sidebar, content } = useSelector(state => ({
        sidebar: state.sidebar,
        content: state.content,
        theme: state.theme,
        rtl: state.rtl,
    }));

    const dispatch = useDispatch();

    const sidebarVisibility = () => {
        dispatch(changeSidebarVisibility());
        dispatch(changeContentVisibility());

    };

    const mobileSidebarVisibility = () => {
        dispatch(changeMobileSidebarVisibility());
        dispatch(changeMobileContentVisibility());

    };

    const changeToDark = () => {
        dispatch(changeThemeToDark());
    };

    const changeToLight = () => {
        dispatch(changeThemeToLight());
    };

    useEffect(() => {
        if (localStorage.getItem('userIsLoggedIn') == '1') {
            let user = emailArray.find(o => o.username.toLocaleLowerCase() == localStorage.getItem('loggedInUserEmail'))
            if (user) {
                authenticateUser(user);
                setTopbarUsername('');
            }
            else {
                setLoginStatus(true);
                setTopbarUsername('');
            }
        }
    }, []);

    const authenticateUser = (user) => {
        setGraphData({ 'displayName': user.name, 'mail': 'fleet.management@lynkco.com', 'givenName': user.name });
        checkoutAccount({ 'displayName': user.name, 'mail': 'fleet.management@lynkco.com', 'givenName': user.name })
        QueryUserByEmailExternalUsers('fleet.management@lynkco.com').then((serviceResponse) => {
            serviceResponse.tokenExpiry = Date.now() + serviceResponse.tokenExpiry - 5000
            getUniversalCookies().set('RefreshToken', serviceResponse.refreshToken);
            serviceResponse && serviceResponse.organization ?
                setUserServiceInfo(serviceResponse)
                :
                setUserServiceInfo({
                    organization: { id: "" }, role: {}
                })

            serviceResponse && serviceResponse.permissions ?
                setUserPermission(serviceResponse.permissions)
                :
                setUserPermission([])
            setUserIsLoggedIn(true);
            localStorage.setItem('userIsLoggedIn', '1');
            localStorage.setItem('loggedInUserEmail', user.username);
        })
    };

    const onSubmit = async values => {
        let dataValue = JSON.parse((JSON.stringify(values, 0, 2)));
        let user = emailArray.find(o => o.username.toLocaleLowerCase() == dataValue.username.toLocaleLowerCase() && userPassword == dataValue.password)
        if (user) {
            setLoading(true);
            authenticateUser(user);
            setTopbarUsername(user.name);
        }
        else
            setLoginStatus(true);
    };

    const checkoutAccount = (graphData) => {
        SetAdUserDetails({
            ...adUserDetails,
            accountName: graphData.displayName,
            initials: graphData.displayName.charAt(0),
            surname: '',
            givenName: '',
            displayName: graphData.displayName,
            thumbnailPhoto: '',
            email: graphData.mail,
            isAdmin: false,
            graphData: graphData
        });
        setUserPhoto(adUserDetails); // To be used to set user photo after userAD has been set successfully
        dummyAsyncCall().then(() => setLoading(false));
    };

    const setUserPhoto = (userAD) => {
        getSignedInUserPhoto_Beta().then((response) => {
            if (!response.status) {
                if (userAD) {
                    userAD.thumbnailPhoto = null;
                    SetAdUserDetails(userAD);
                }
            }
            else {
                response.blob.then((data) => {
                    const reader = new FileReader()
                    reader.readAsDataURL(data)
                    reader.onload = () => {
                        if (userAD) {
                            const base64data = reader.result;
                            userAD.thumbnailPhoto = base64data;
                            SetAdUserDetails(userAD);
                        }
                    }
                })
            }
        });
    };

    const dummyAsyncCall = () => {
        return new Promise((resolve) => setTimeout(() => resolve(), 10500));
    };

    return (
        <>
            {
                !userIsLoggedIn ?

                    <div className="black-background">
                        <Row style={{ marginTop: '50px', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>

                            <Col md={{ span: 4 }}>

                                <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', }}>
                                    <LoginLogo to="/vehicles" />
                                </div>

                                <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                    {/* <span style={{ fontSize: '15px', fontFamily: "LynkcoTypeRegular", marginTop: '5px', letterSpacing: '3px', color: '#fff' }}>CONNECTED SUPPORT</span> */}
                                </div>

                                <div style={{ marginTop: '80px' }}>

                                    <>
                                        <Form onSubmit={onSubmit}>
                                            {({ handleSubmit }) => (
                                                <LoginForm onSubmit={handleSubmit}>
                                                    <FormGroup>
                                                        <FormGroupLabel>{'Username'}</FormGroupLabel>
                                                        <FormGroupField>
                                                            <FormGroupIcon>
                                                                <AccountOutlineIcon />
                                                            </FormGroupIcon>
                                                            <Field
                                                                name="username"
                                                                component="input"
                                                                placeholder={'Username'}
                                                                className="input-without-border-radius"
                                                                style={{ borderTopRightRadius: '15px', borderBottomRightRadius: '15px', borderLeft: 'none', borderRight: '1px solid #33333a', height: '40px' }}
                                                            />
                                                        </FormGroupField>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <FormGroupLabel>Passwordsss</FormGroupLabel>
                                                        <FormGroupField>
                                                            <Field
                                                                name="password"
                                                                component={PasswordField}
                                                                placeholder="Password"
                                                                className="input-without-border-radius"
                                                                style={{ height: '40px' }}
                                                                keyIcon
                                                            />
                                                            <AccountForgotPassword>

                                                                <span style={{ fontSize: 'calc(8px + 0.390625vw)', fontFamily: "LynkcoTypeLight", color: '#fff' }}>
                                                                    <FormGroupLabel>
                                                                        {loginStatus ? <span style={{ color: '#DC1860' }}>Invalid username or password</span> : 'Forgot your password?'}
                                                                    </FormGroupLabel>
                                                                </span>

                                                            </AccountForgotPassword>
                                                        </FormGroupField>

                                                    </FormGroup>
                                                    <FormGroup>
                                                        {/* <span style={{ fontSize: '14px', fontFamily: "LynkcoTypeLight", marginTop: '10px', color: '#fff' }}>
                                                            Inavid user name or password
                                                        </span> */}

                                                        <Row style={{ marginBottom: '8px', marginTop: '150px' }}>
                                                            <Col lg="6" md="6" sm="6" xs="6">
                                                                <span style={{ float: 'left' }}>
                                                                    <SearchButton
                                                                        placeholder={'Lynk & Co SSO'}
                                                                        iconProp='true'
                                                                        handleSearch={() => instance.loginPopup()}
                                                                    />
                                                                </span>
                                                            </Col>
                                                            <Col lg="6" md="6" sm="6" xs="6">
                                                                <span style={{ float: 'right', marginLeft: '80px' }}>
                                                                    <SearchButton
                                                                        placeholder={'Sign In'}
                                                                        height='true'
                                                                        widthProp='true'
                                                                        type="submit"
                                                                    />
                                                                </span>
                                                            </Col>
                                                        </Row>

                                                    </FormGroup>
                                                    <FormGroup>
                                                        {/* <Row style={{ marginBottom: '8px', marginTop: '15px', }}>
                                                            <Col lg="2" md="2" sm="10" xs="10"></Col>
                                                            <Col lg="10" md="10" sm="10" xs="10"> */}
                                                        {/* <div style={{ paddingLeft: '80px' }}>
                                                                    <tr>
                                                                        <td>
                                                                            <span style={{ fontSize: '14px', fontFamily: "LynkcoTypeLight", marginTop: '30px', color: '#fff', float: 'right', }}>Need help? Please</span>
                                                                        </td>
                                                                        <td>
                                                                            <span style={{ fontSize: '14px', fontFamily: "LynkcoTypeLight", marginTop: '30px', color: '#fff', float: 'right', textDecoration: 'underline', cursor: 'pointer' }}> reach out to us</span>

                                                                        </td>
                                                                    </tr>
                                                                </div> */}
                                                        <FormGroupField>
                                                            <AccountForgotPassword>

                                                                <span style={{  fontFamily: "LynkcoTypeLight", color: '#fff' }}>
                                                                    <FormGroupLabel>
                                                                        <tr>
                                                                            <td>
                                                                                <span style={{ fontSize: 'calc(8px + 0.390625vw)', fontFamily: "LynkcoTypeLight", marginTop: '30px', color: '#fff',  }}>Need help? Please</span>
                                                                            </td>
                                                                            <td>
                                                                                <span style={{ fontSize: 'calc(8px + 0.390625vw)', fontFamily: "LynkcoTypeLight", marginTop: '30px', color: '#fff', textDecoration: 'underline', cursor: 'pointer' }}> reach out to us</span>

                                                                            </td>
                                                                        </tr>                                                                    </FormGroupLabel>
                                                                </span>

                                                            </AccountForgotPassword>
                                                        </FormGroupField>

                                                        {/* </Col>
                                                        </Row> */}
                                                    </FormGroup>

                                                </LoginForm>
                                            )}
                                        </Form>
                                    </>

                                </div>


                            </Col>
                        </Row>

                    </div>
                    :
                    <div className="black-background">
                        {
                            loading ?

                                <div id="main-wrapper" >
                                    <Welcome image={bgImage} ></Welcome>
                                </div>
                                :

                                <div id="main-wrapper"
                                    dir={settings.activeDir}
                                    data-theme={settings.activeTheme}
                                    data-layout={settings.activeThemeLayout}
                                    data-sidebartype={settings.activeSidebarType}
                                    data-sidebar-position={settings.activeSidebarPos}
                                    data-header-position={settings.activeHeaderPos}
                                    data-boxed-layout={settings.activeLayout}
                                >

                                    <LayoutContainer collapse={sidebar.collapse}>
                                        <Topbar
                                            {...props}
                                            userName={topbarUsername}
                                            adUserDetails={adUserDetails}
                                            changeMobileSidebarVisibility={mobileSidebarVisibility}
                                            changeSidebarVisibility={sidebarVisibility}
                                            setUserIsLoggedIn={setUserIsLoggedIn}
                                        />

                                        {/* <Sidebar {...props} userName={topbarUsername} routes={SidebarData} adUserDetails={adUserDetails}
                                            sidebar={sidebar}
                                            changeToDark={changeToDark}
                                            changeToLight={changeToLight}
                                            changeMobileSidebarVisibility={mobileSidebarVisibility}
                                            setUserIsLoggedIn={setUserIsLoggedIn}
                                        /> */}

                                    </LayoutContainer>

                                    <ContainerWrap collapse={content.collapse} >
                                        <Content content={content} />
                                    </ContainerWrap>
                                </div>
                        }

                    </div>
            }
        </>
    );
};


LogInForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    fieldUser: PropTypes.string,
    typeFieldUser: PropTypes.string,
    form: PropTypes.string.isRequired,
};

LogInForm.defaultProps = {
    errorMessage: '',
    fieldUser: 'Username',
    typeFieldUser: 'text',
};

const mapStateToProps = state => ({
    graphData: selectUserGraphData(state),
    adUserDetails: selectAdUserDetails(state),
    userPermissions: selectUserPermissions(state),
    userToken: selectUserToken(state)
});

const mapDispatchToProps = dispatch => ({
    setGraphData: graphData => dispatch(SetUserGraphData(graphData)),
    SetAdUserDetails: adUserDetails => dispatch(SetAdUserDetails(adUserDetails)),
    setUserServiceInfo: userServiceInfo => dispatch(SetUserServiceInfo(userServiceInfo)),
    setUserPermission: userPermissions => dispatch(SetUserPermission(userPermissions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(LoginPage));


const LayoutContainer = styled.div`
    & + div {
      ${props => props.collapse && `
        ${paddingLeft(props)}: 0;
      `};
  
      @media screen and (min-width: 576px) {
        ${props => props.collapse && `
          ${paddingLeft(props)}: 60px;
        `}
  
        ${props => props.topNavigation && `
           ${paddingLeft(props)}: 0;
        `}
      }
    }
  `;

const ContainerWrap = styled.div`
    margin-left: ${(props) => (props.collapse ? '200px' : paddingLeft(props))};
  
  `;